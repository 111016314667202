import React from 'react';
import TableComponent from './TableComponent';
import { ScoreTable, TablePageContent } from './TablePageComponents';
import WinnersSchoolTable from './WinnersSchoolTable';
import { useTranslation } from 'react-i18next';

export default function TableComponentsWithWinner(props)
{
    const { t } = useTranslation();
    let firstScore = 0,
        secondScore = 0,
        selfScore = 0,
        lastWeekScore = 0;
    let firstSchoolName = "no one played",
        secondSchoolName = "no one played",
        selfSchoolName = "no one played",
        lastWinnerSchool = "no one played";
    const game = props.game;

    if (game[1])
    {
        firstScore = game[1].highscore ? game[1].highscore : 0;
        firstSchoolName = game[1].floorname ? "1. " + game[1].floorname : "n";
    }

    if (game[2])
    {
        secondScore = game[2].highscore ? game[2].highscore : 0;
        secondSchoolName = game[2].floorname ? "2. " + game[2].floorname : "n";
    }

    if (game.self)
    {
        selfScore = game.self.highscore ? game.self.highscore : 0;
        selfSchoolName = game.self.floorname ? game.self.position + ". " + game.self.floorname : 0;
    }
    else if (game[3])
    {
        selfScore = game[3].highscore ? game[3].highscore : 0;
        selfSchoolName = game[3].floorname ? "3. " + game[3].floorname : 0;
    }

    if (props.lastWeekScore
        && props.lastWeekScore[1])
    {
        lastWeekScore = props.lastWeekScore[1].highscore;
        lastWinnerSchool = props.lastWeekScore[1].floorname;
    }

    return <ScoreTable className="outerTableContent tableComponentsWithWinner" style={{ paddingBottom: "99%" }}>
        <TablePageContent className="innerContent">
            <TableComponent
                number={props.number}
                gameName={props.gameName}
                firstScore={firstScore}
                firstSchoolName={firstSchoolName && firstSchoolName.toUpperCase()}
                secondScore={secondScore}
                secondSchoolName={secondSchoolName && secondSchoolName.toUpperCase()}
                selfScore={selfScore}
                selfSchoolName={selfSchoolName && selfSchoolName.toUpperCase()}
                t={t}
                height="66%"
            />
            <WinnersSchoolTable
                header={lastWinnerSchool && lastWinnerSchool.toUpperCase()}
                score={lastWeekScore}
                t={t}
                BackgroundColor={"Blue"}
            />
        </TablePageContent>
    </ScoreTable>;
}
