import React, { Component } from "react";
import logo from "./img/floors.png";
import styled from "styled-components";
import { fontSize } from "./generatedStyle/_dashboardMixins";
import breakpoint from 'styled-components-breakpoint';

const TimeBarDiv = styled.div`
    height: 100%;
    z-index: 1;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;

    h1 {
        ${breakpoint('mobile')`
            ${fontSize(10)};
        `}
        ${breakpoint('tablet')`
            ${fontSize(15)};
        `}
        ${breakpoint('desktop')`
            ${fontSize(25)};
        `}
    }

    .logoComponent
    {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        margin-top: 20%;
        img
        {
            max-width: 80%;
        }
    }

    h1#sideBarHeader
    {
        color: #ffffff;
        font-weight: 550;
        padding: 5px;
    }

    #countholder
    {
        font-weight: bold;

        h1 {
            font-weight: bold;
            color: #ffffff;
            ${breakpoint('mobile')`
                ${fontSize(55)};
            `}
            ${breakpoint('tablet')`
                ${fontSize(65)};
            `}
            ${breakpoint('desktop')`
                ${fontSize(90)};
            `}
            line-height: 1;
        }

        h1#timerdays {
            color: #FFFFFF;
        }

        h6 {
            color: #ffffff;
            font-weight: bold;
            ${breakpoint('mobile')`
                ${fontSize(10)};
            `}
            ${breakpoint('tablet')`
                ${fontSize(16)};
            `}
            ${breakpoint('desktop')`
                ${fontSize(18)};
            `}
        }
    }
`;

export default class TimeBar extends Component
{
    state = {
        curday: 0,
        sectTime: 0,
        ticker: null
    };
    componentDidMount()
    {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.getSeconds(), 1000);
    }

    componentWillUnmount()
    {
        clearInterval(this.interval);
        clearInterval(this.state.ticker);
        this.setState({ curday: 0, sectTime: 0, ti15cker: null });
    }
    getSeconds = () =>
    {
        let nowDate = new Date();
        let dy = 1; //Monday through Saturday, 0 to 6
        let countertime = new Date(
            nowDate.getFullYear(),
            nowDate.getMonth(),
            nowDate.getDate(),
            0,
            0,
            0
        ); //0 out of 24 hours = 0pm

        let curtime = nowDate.getTime(); //current time
        let atime = countertime.getTime(); //countdown time
        let diff = parseInt((atime - curtime) / 1000);
        if (diff > 0)
        {
            this.setState({ curday: dy - nowDate.getDay() });
        }
        else
        {
            this.setState({ curday: dy - nowDate.getDay() - 1 });
        } //after countdown time
        if (this.state.curday < 0)
        {
            this.setState({ curday: this.state.curday + 7 });
        } //already after countdown time, switch to next week
        if (diff <= 0)
        {
            diff += 86400 * 7;
        }
        this.startTimer(diff);
    };

    startTimer = secs =>
    {
        let sectTime = secs;

        clearInterval(this.state.ticker);
        const ticker = setInterval(() => this.tick(), 1000);
        this.setState({ sectTime, ticker });

        this.tick(); //initial count display
    };

    tick = () =>
    {
        let secs = this.state.sectTime;
        if (secs > 0)
        {
            secs--;
            this.setState({ sectTime: secs });
        }
        else
        {
            clearInterval(this.state.ticker);
        }
    };

    render()
    {
        const { t } = this.props;
        let secs = this.state.sectTime;
        secs %= 86400;
        let hours = Math.floor(secs / 3600);
        secs %= 3600;
        let mins = Math.floor(secs / 60);
        secs %= 60;
        return (
            <TimeBarDiv className="timeBar">
                <h1 id="sideBarHeader">BEAT YOUR<br />HIGHSCORE<br />IN:</h1>
                <div id="countholder">
                    <div className="timeContent">
                        <h1 id="timerdays">
                            {this.state.curday < 10
                                ? "0" + this.state.curday
                                : this.state.curday}
                        </h1>
                        <h6>{t('days')}</h6>
                    </div>
                    <div className="timeContent" >
                        <h1>{hours < 10 ? "0" + hours : hours}</h1>
                        <h6>{t('hours')}</h6>
                    </div >
                    <div className="timeContent">
                        <h1>{mins < 10 ? "0" + mins : mins}</h1>
                        <h6>{t('minutes')}</h6>
                    </div>
                    <div className="timeContent">
                        <h1>{secs < 10 ? "0" + secs : secs}</h1>
                        <h6>{t('seconds')}</h6>
                    </div>
                </div>
                <div className="logoComponent">
                    <img src={logo} alt="logo" />
                </div>
            </TimeBarDiv>
        );
    }
}
