import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from 'styled-components-grid';

import TableComponentsWithWinner from '../components/TableComponentsWithWinner';
import { TablePageComponent } from '../components/TablePageComponents';


function WeeklyMaximumScoresContent(props)
{
    const { t } = useTranslation();

    const allTableRef = React.createRef();
    return <TablePageComponent className="tableAll allScores weeklyMaximumScoresContent" ref={allTableRef}>
        {props.scores.length > 0 &&
            props.scores.map((game, index) =>
            {
                return <Grid.Unit size={{ mobile: 1, tablet: 1 / 2, desktop: 1 / 3 }} key={index} className="scoreTables" >
                    <TableComponentsWithWinner
                        number={index + 1}
                        gameName={game.gamename.toUpperCase()}
                        game={game}
                        lastWeekScore= {props.lastWeekScore[index]}
                        t={t}
                    />
                </Grid.Unit>;
            })}
    </TablePageComponent>;
}
export default WeeklyMaximumScoresContent;