const configs = {
    dev: {
        url: "http://localhost:8080/v1/",
        assets: "http://localhost:3000"
    },
    test: {
        url: "https://beta.api.efcloud.nl/v1/",
        assets: "https://beta.dashboard.efcloud.nl"
    },
    production: {
        url: "https://api.apollios.com/v1/",
        assets: "https://dashboard.apollios.com"
    }
}

export default configs[process.env.REACT_APP_STAGE];