import React, { Component } from 'react'

export default class NoMatch extends Component {
    render() {
        return (
            <div style={{ margin: "auto" }}>
                404
            </div>
        )
    }
}
