
function calcFontsize(baseWidth, width, multiplier) {
    if (!multiplier) {
        multiplier = 1;
    }
    return (multiplier * width / baseWidth) + "em";
}

export {
    calcFontsize
}