import React, { Component } from 'react';

export default class Home extends Component
{
    render()
    {
        return (
            <div className="homeContainer">
                <h2>No data to show</h2>
                <p>Please request a dashboard link at <a href="mailto:info@apollios.com">info@apollios.com</a></p>
            </div>
        );
    }
}
