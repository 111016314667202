import { useState, useEffect } from 'react';
import React from 'react';

import TableComponentsWithWinner from '../components/TableComponentsWithWinner';
import { getWeeklyHighestSchoolScoresGlobal, getLastWeekHighestScoreGlobal } from "../components/data/api";

export default function EmbeddedWeeklyMaximumScores(props)
{
    const [thisWeekScores, setThisWeekScores] = useState();
    const [lastWeekScore, setLastWeekScore] = useState();

    useEffect(() =>
    {
        getWeeklyHighestSchoolScoresGlobal().then(data =>
        {
            setThisWeekScores(data);
        });
    }, []);

    useEffect(() =>
    {
        getLastWeekHighestScoreGlobal().then(data =>
        {
            setLastWeekScore(data);
        });
    }, []);

    if (thisWeekScores && lastWeekScore)
    {
        const gameIndex = parseInt(props.match.params.game, 10);

        const game = thisWeekScores[gameIndex];
        return <TableComponentsWithWinner
            number={gameIndex + 1}
            gameName={game.gamename.toUpperCase()}
            game={game}
            lastWeekScore={lastWeekScore[gameIndex]}
        />;
    }
    else
    {
        return <div>loading</div>;
    }
}