import config from './data/config';
import { compile } from 'path-to-regexp';

function createPathObject(path)
{
    return {
        path: path,
        toPath: compile(path)
    };
}

const root = createPathObject(config.baseurl);

const weeklyScoredSchoolGlobal = createPathObject(config.baseurl + "energyFloors/:lang");
const weeklyScoredSchool = createPathObject(config.baseurl + "energyFloors/:lang/:floorId");
const weeklyHighestScoredAllSchoolGlobal = createPathObject(config.baseurl + "energyFloors/highestScored/:lang");
const weeklyHighestScoredAllSchool = createPathObject(config.baseurl + "energyFloors/highestScored/:lang/:floorId");
const solarEnergyScoredSchool = createPathObject(config.baseurl + "energyFloors/solarEnergy/:lang/:floorId");

const embeddedHighScore = createPathObject(config.baseurl + "energyFloors/:lang/embedded/highestScored/:game");

export {
    root,
    weeklyScoredSchoolGlobal,
    weeklyScoredSchool,
    weeklyHighestScoredAllSchool,
    weeklyHighestScoredAllSchoolGlobal,
    solarEnergyScoredSchool,
    embeddedHighScore
};