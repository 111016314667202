import React, { Component } from 'react';
import { Link } from "react-router-dom";
import pause from "./img/pause.png";
import play from "./img/play.png";

import * as Paths from './Paths';

import styled from 'styled-components';
import colors from './generatedStyle/_dashboardColors';
import { fontSize } from './generatedStyle/_dashboardMixins';

const HeaderDiv = styled.div`{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .leftHeader {
      margin-left: 2%;
      text-align: left;
      color: ${colors.textColor};
      width: 80%;
      h1 {
        font-weight: 700;
      }
      h4 {
        font-weight: 550;
      }
    }

    .rightHeader {
      display: flex;
      justify-content: space-around;
      margin-right: 11%;
      margin-top: 1%;

      h6 {
          font-size: 1rem;
          line-height: 1.2;
      }
      .sliderHeader {
        display: flex;
        justify-content: space-between;
        div {
          margin-left: 45%;
          ${fontSize(14)}
          a {
            color: black;
          }
        }

        div.pauseButton {
          cursor: pointer;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .selected {
          h6 {
            background-color: ${colors.thisWeekColor};
            height: 20px;
            width: 20px;
            border-radius: 50%;
            text-align: center;
            a {
              color: #ffffff;
            }
          }
        }
      }
    }
}`;


export default class Header extends Component
{
    stopPause = () =>
    {
        this.props.pauseInterval();
        this.props.setIsPaused(true);
    }

    startPause = () =>
    {
        this.props.startInterval();
        this.props.setIsPaused(false);
    }

    render()
    {
        let floorId = this.props.floorId;
        let lang = this.props.lang;
        let img = this.props.isPaused ? play : pause;
        return (
            <HeaderDiv className="headerComponent">
                <div className="header leftHeader">
                    {this.props.firstHeader && <h1>{this.props.firstHeader}</h1>}
                    {this.props.secondFirstHeader && <h1>{this.props.secondFirstHeader}</h1>}
                    {this.props.secondHeader && <h4>{this.props.secondHeader}</h4>}
                </div>
                <div className="header rightHeader">
                    <div className="sliderHeader">
                        <div onClick={this.props.isPaused ? this.startPause : this.stopPause} className="pauseButton">
                            <h6><img src={img} alt="img" /></h6>
                        </div>
                        <div className={this.props.classNameSchoolScores}>
                            <h6><Link to={
                                floorId ?
                                    Paths.weeklyScoredSchool.toPath({ floorId: floorId, lang: lang }) :
                                    Paths.weeklyScoredSchoolGlobal.toPath({ lang: lang })
                            }>1</Link></h6>
                        </div>
                        <div className={this.props.classNameMaxScore}>
                            <h6><Link to={
                                floorId ?
                                    Paths.weeklyHighestScoredAllSchool.toPath({ floorId: floorId, lang: lang }) :
                                    Paths.weeklyHighestScoredAllSchoolGlobal.toPath({ lang: lang })
                            }>2</Link></h6>
                        </div>
                        <div className={this.props.classNameSolarEnergyScore}>
                            <h6><Link to={Paths.solarEnergyScoredSchool.toPath({ floorId: floorId, lang: lang })}>3</Link></h6>
                        </div>

                    </div>
                </div>
            </HeaderDiv >
        );
    }
}
