
import React from 'react';
import { useTranslation } from 'react-i18next';
import Speedometer from './Speedometer';
import colors from './generatedStyle/_dashboardColors.js';
import styled from 'styled-components';


const EnergyContainer = styled.div`{
    display: flex;
    justify-content: flex-start;
    height: 100%;
    margin: 2%;

    .side {
        width: 45%;
    }

    .leftSide {
        background: ${colors.energyBackgroundColor};
        color: ${colors.textColor};
        z-index: 1;
    }
    .rightSide {
        min-height: 50vh;
    }

    .solarTable {
        height: 25%;
        min-height: 15vh;
        width: 80%;
        margin: auto;
        text-align: left;
        padding-bottom: 3%;
        margin-bottom: 5%;
        div {
            padding-left : 3%;
        }
        .header {
            padding-top: 4%;
            height: 40%;
        }
        .value {
            margin-top: -2%;
            height: 65%;
            width: 100%;
        }
        .unit {
            padding-left: 2%;
            display: inline-block;
        }
    }

    .solarToday {
        background-color: ${colors.energyBackgroundColor};
        color: ${colors.textColor};
    }

    .peakToday {
        background-color: ${colors.todayColor};
        color: white;
    }
}`;

function SolarEnergy(props)
{
    const { t } = useTranslation();

    let { producedToday, maxProduced } = props.energy ? props.energy : { producedToday: "0000", maxProduced: 0 };

    producedToday = producedToday ? String(producedToday).slice(0, 4) : producedToday;
    maxProduced = maxProduced > 0 ? String(maxProduced).slice(0, 4) : maxProduced;

    return <EnergyContainer className="energyContainer">
        <div className="leftSide side">
            <Speedometer
                energy={props.energy}
                t={t}
            />
        </div>

        <div className="rightSide side">
            <div className="solarTable solarToday">
                <div className="header fitted">{t('totalToday')}</div>
                <div className="value fitted">
                    {producedToday}{" KWH"}
                </div>

            </div>
            <div className="solarTable peakToday">
                <div className="header fitted">{t('piekToday')}</div>
                <div className="value fitted">
                    {maxProduced}{" WATT"}
                </div>
            </div>
        </div>
    </EnergyContainer>;
}
export default SolarEnergy;