import React, { Component } from 'react';
import { calcFontsize } from './general';
import colors from './generatedStyle/_dashboardColors.js'

import styled from 'styled-components';

const Wrapper = styled.div`
    position: static;
    margin: 3% auto auto auto;
    width: 85%;
    height: 34%;
    .winnerHeader {
        text-align: left;
        color: ${colors.textColor};
        font-weight: bold;
        padding-bottom: 1%;
    }

    .winnerTexts {
        display: inline-block;
        width: 68%;
        height: 100%;
    }

    .winnerScores {
        .winnerSchool {
        margin-left: 3%;
        padding-top: 3%;
        font-weight: bold;
        width: 100%;
        height: 48%;
        }
        .winnerSchool2 {
        margin-left: 3%;
        font-weight: bold;
        width: 100%;
        height: 45%;
        }
        .scoreSchool {
        display: inline-block;
        text-align: right;
        height: 100%;
        width: 20%;
        font-family: "Gotham-Light";
        .scoreBold {
            font-family: "Gotham-Bold";
        }
        }
        width: 100%;
        height: 60%;
        margin: auto;
        border-radius: 3px;
        text-align: left;
    }

    .winnerBlue {
        background: ${colors.textColor};
        color: #ffffff;
    }

    .winnerGrey {
        background: ${colors.sideBarTimeColor};
        color: ${colors.textColor};
    }
}`;

export default class WinnersSchoolTable extends Component
{
    constructor(props)
    {
        super(props);
        this.myRef = React.createRef();
    }

    state = {
        width: null
    };

    drawEnergy()
    {
        return (<div><span>{this.props.energy.score}</span>{"  "}{this.props.energy.title}</div>);
    }

    componentDidMount()
    {
        window.addEventListener("resize", () => this.onResize());
        if (this.myRef.current)
        {
            this.setState({ width: this.myRef.current.offsetWidth });
        }
        this.onResize();
    }

    onResize()
    {
        if (this.myRef.current)
        {
            this.setState({ width: this.myRef.current.offsetWidth });
        }
    }

    render()
    {
        let fontSize = 1;
        let fontSizeScore = 1;
        if (this.state.width)
        {
            fontSize = calcFontsize(271, this.state.width, .97);
            fontSizeScore = calcFontsize(271, this.state.width, 2);
        }
        const { t } = this.props;
        let text = this.props.header ? this.props.header : "";
        let index = text.lastIndexOf(" ");
        let line1 = null;
        let line2 = null;
        if (index !== -1)
        {
            line1 = text.substring(0, index);
            line2 = text.substring(index + 1);
        }
        else
        {
            line1 = text;
            line2 = "";
        }

        return (
            <Wrapper className={"winnerContent winnerContent" + this.props.BackgroundColor + " " + this.props.className} ref={this.myRef}>
                {this.props.score >= 0 && <div className="winnerHeader" style={{ fontSize: fontSize }}>{t('winnerPreviousWeek')}</div>}
                <div className={"winnerScores winner" + this.props.BackgroundColor}>
                    <div className="winnerTexts">
                        <div className="winnerSchool" style={{ fontSize: fontSize }}>{line1}</div>
                        <div className="winnerSchool2" style={{ fontSize: fontSize }}>{line2}</div>
                    </div>
                    {this.props.score >= 0 &&
                        <div className="scoreSchool">
                            <span className="scoreBold" style={{ fontSize: fontSizeScore }}>{" " + this.props.score}</span>
                        </div>
                    }
                    {this.props.energy && this.drawEnergy()}
                </div>
            </Wrapper>
        );
    }
}
