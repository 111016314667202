import React, { Component } from 'react';
import manageLanguage from '../components/manageLanguage';
import Header from '../components/Header';
import SolarEnergy from '../components/SolarEnergy';
import { getSolarEnergyForSchool } from "../components/data/api";
import textfit from 'textfit';

class SchoolSolarEnergy extends Component
{
    state = {
        energy: null
    }
    componentDidMount()
    {
        let id = this.props.getId();
        this.getTheSolarEnergy(id);
    }

    componentDidUpdate()
    {
        textfit(document.getElementsByClassName('fitted'));
    }


    getTheSolarEnergy = (id) =>
    {
        let _this = this;
        getSolarEnergyForSchool(id).then(response =>
        {
            if (response === null)
            {
                console.error("No response");
            }
            else
            {
                _this.setState({ energy: response });
            }
        }).catch(err =>
        {
            console.log(err);
        });

    }

    render()
    {
        if (this.state.energy)
        {
            return (
                <div className="schoolSolarEnergy">
                    <Header
                        firstHeader={this.props.t('ZONNE ENERGIE')}
                        secondFirstHeader={this.props.t('today')}
                        floorId={this.props.getId()}
                        classNameSolarEnergyScore="selected"
                        lang={this.props.getLanguage()}
                        t={this.props.t}
                        pauseInterval={this.props.pauseInterval}
                        startInterval={this.props.startInterval}
                        isPaused={this.props.isPaused}
                        setIsPaused={this.props.setIsPaused}
                    />
                    <SolarEnergy energy={this.state.energy} />
                </div >
            );
        }
        else
        {
            return <></>;
        }
    }
}

export default manageLanguage(SchoolSolarEnergy);
