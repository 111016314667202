import React, { Component } from "react";
import textfit from 'textfit';
import { calcFontsize } from './general';
import colors from './generatedStyle/_dashboardColors.js'
import styled from 'styled-components';
import { TablePageContent } from "./TablePageComponents";
import apiConfig from "./data/apiConfig";

const OuterTable = styled.div`
{
    width: 100%;
}
`;

const Table = styled.div`
    width: 100%;
    position: relative;

    .scoreBoard
    {
      position: absolute;
      right: 15%;
      z-index: 100;
      width: 20%;
      height: 100%;
    }

    .gamelogo {
      width: 10%;
      position: absolute;
      left: 8.0%;
      top: -5%;
    }

    .scoreResult
    {
      width: 100%;
      font-weight: bold;
      text-align: center;
    }

    .score1
    {
      height: 33%;
    }
    .score2
    {
      height: 26.5%;
    }
    .score3
    {
      height: 23%;
    }

    .headerContent {
      text-align: center;
      width: 62%;
      color: ${colors.textColor};
      font-weight: bold;
      height: 15%;
    }

    .scoreResult {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 15%;
      font-family: "Gotham-Light";
      font-weight: 600;
      z-index: 1000;
      .scoreBold {
        font-family: "Gotham-Bold";
      }
    }

    .todayScore {
      background-color: ${colors.todayColor};
      width: 85%;
      height: 33%;
      padding-left: 3%;
      .timesScore {
        margin-top: 7%;
        width: 70%;
        height: 50%;
      }
      .winnerScore
      {
        position: absolute;
        left: 3%;
        text-align: left;
        width: 60%;
        height: 100%;
        .lineScore {
          height: 50%;
        }
      }
    }

    .thisWeekScore {
      background-color: ${colors.thisWeekColor};
      width: 75%;
      height: 26.5%;
      padding-left: 3%;
      .timesScore {
        margin-top: 4.5%;
        width: 74%;
        height: 60%;
      }
      .winnerScore
      {
        position: absolute;
        left: 3%;
        text-align: left;
        width: 70%;
        height: 100%;
        .lineScore {
          height: 50%;
        }
      }
    }

    .allTimeScore {
      background-color: ${colors.allDayColor};
      width: 65%;
      height: 23%;
      padding-left: 3%;
      .timesScore {
        margin-top: 4.0%;
        width: 78%;
        height: 70%;
      }
      .winnerScore
      {
        position: absolute;
        left: 3%;
        text-align: left;
        width: 70%;
        height: 100%;
        .lineScore {
          height: 40%;
        }
      }
    }

    div {
      position: relative;
      margin: auto;
      border-radius: 3px;
      text-align: left;
      color: #ffffff;
      .timesScore {
        color: ${colors.blackColor};
        display: inline-block;
        height: 100%;
        font-weight: bold;
      }
      span {
        font-weight: bold;
      }
    }
`;

export default class TableComponent extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
    }

    state = {
        width: null
    };

    drawBoxBody(word, schoolname, fontSize)
    {
        if (schoolname)
        {
            let index = schoolname.lastIndexOf(" ");
            let line1 = null;
            let line2 = null;
            if (index !== -1) {
                line1 = schoolname.substring(0, index);
                line2 = schoolname.substring(index + 1);
            }
            else {
                line1 = schoolname;
                line2 = "";
            }
            return <div className="winnerScore">
                <div className="lineScore" style={{ fontSize: fontSize }}>{line1}</div>
                <div className="lineScore" style={{ fontSize: fontSize }}>{line2}</div>
            </div>;
        }
        else
        {
            return <div className="timesScore" >{word}</div>
        }
    }

    drawContent()
    {
        let fontsizeTitle = calcFontsize(317, this.state.width, .9);
        let fontSize = calcFontsize(317, this.state.width, .9);
        let fontsize2 = calcFontsize(317, this.state.width, .9);
        let fontsize3 = calcFontsize(317, this.state.width, .8);
        let fontsizeScore = calcFontsize(317, this.state.width, 2);
        let fontsizeScore2 = calcFontsize(317, this.state.width, 1.8);
        let fontsizeScore3 = calcFontsize(317, this.state.width, 1.6);
        const { t } = this.props;
        return (
            <Table className="tableContent" style={{ height: this.props.height }} ref={this.myRef}>
                <img className="gamelogo" src={apiConfig.assets +  "/ENF_0" + this.props.number + ".png"} alt="gamelogo"></img>
                <div className="headerContent" style={{ fontSize: fontsizeTitle }}>
                    {this.props.number + " " + this.props.gameName}
                </div>
                <div className="scoreBoard">
                    <div className="scoreResult score1" style={{ fontSize: fontsizeScore }}>
                        {" " + this.props.firstScore}
                    </div>
                    <div className="scoreResult score2" style={{ fontSize: fontsizeScore2 }}>
                        {" " + this.props.secondScore}
                    </div>
                    <div className="scoreResult score3" style={{ fontSize: fontsizeScore3 }}>
                        {" " + this.props.selfScore}
                    </div>
                </div>
                <div className="todayScore">
                    {this.drawBoxBody(t('today'), this.props.firstSchoolName, fontSize)}
                </div>
                <div className="thisWeekScore">
                    {this.drawBoxBody(t('thisWeek'), this.props.secondSchoolName, fontsize2)}
                </div>
                <div className="allTimeScore">
                    {this.drawBoxBody(t('overAll'), this.props.selfSchoolName, fontsize3)}
                </div>
            </Table>);
    }

    componentDidMount()
    {
        textfit(document.getElementsByClassName('fitted'));
        window.addEventListener("resize", () => this.onResize());
        if (this.myRef.current)
        {
            this.setState({ width: this.myRef.current.offsetWidth });
        }
        this.onResize();
    }

    onResize()
    {
        if (this.myRef.current)
        {
            this.setState({ width: this.myRef.current.offsetWidth });
        }
    }

    render()
    {
        if (this.props.aspectRatio)
        {
            return (
                <OuterTable className="outerTableContent tableComponent" style={{ paddingBottom: this.props.aspectRatio }}>
                    <TablePageContent className="innerContent">
                        {this.drawContent()}
                    </TablePageContent>
                </OuterTable>
            );
        }
        else
        {
            return this.drawContent();
        }
    }
}
