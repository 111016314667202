import React from 'react';
import { withTranslation } from 'react-i18next';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { reboot, defaultRebootTheme } from 'styled-reboot';

const Style = createGlobalStyle`
    ${reboot}
    @font-face{
        font-family: "Gotham-Bold";
        src:url("${props => (props.assetUrl)}/fonts/Gotham-Bold.otf") format("opentype");
    }

    @font-face{
        font-family: "Gotham-Light";
        src:url("${props => (props.assetUrl)}/fonts/GothamLight.ttf") format("truetype");
    }

    font-family: "Gotham-Bold"
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 27px;
    font-weight: normal;
`;

const theme = defaultRebootTheme;
theme.fontFamilyBase = "Gotham-Bold";

function Embeddable(props)
{
    return <ThemeProvider theme={theme}>
        <div className='Container'>
            <Style assetUrl={props.assetUrl}></Style>
            {props.children}
        </div>
    </ThemeProvider>;
}

export default withTranslation('translation')(Embeddable);

