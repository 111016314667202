import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Embeddable from './Embeddable';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from './ErrorBoundary';
import './i18n';
import "./web.config";
import SolarEnergy from './components/SolarEnergy';
import TableComponentsWithWinner from './components/TableComponentsWithWinner';
import { getSolarEnergyForSchool, getWeeklyHighestSchoolScoresGlobal, getLastWeekHighestScoreGlobal } from "./components/data/api";

if (process.env.REACT_APP_EMBED)
{
    window.initEfCloudGame = function initEfCloudSolarEnergy(element, config, cb)
    {
        const { game, assetUrl } = config;
        getWeeklyHighestSchoolScoresGlobal().then(thisWeekScores =>
        {
            if (thisWeekScores === null)
            {
                const err = "No response";
                console.error(err);
                cb && cb(err);
            }
            else
            {
                getLastWeekHighestScoreGlobal().then(lastWeekScore =>
                {
                    if (lastWeekScore === null)
                    {
                        console.log("No response");
                    }
                    else
                    {
                        const thisWeekScoresForGame = thisWeekScores[game];
                        ReactDOM.render(<Embeddable assetUrl={assetUrl}>
                                <TableComponentsWithWinner
                                    number={game + 1}
                                    gameName={thisWeekScoresForGame.gamename.toUpperCase()}
                                    game={thisWeekScoresForGame}
                                    lastWeekScore={lastWeekScore[game]} />
                        </Embeddable>, element);
                        cb && cb(null);
                    }
                }).catch(err =>
                {
                    console.log(err);
                    cb && cb(err);
                });
            }
        }).catch(err =>
        {
            console.log(err);
            cb && cb(err);
        });
    };

    window.initEfCloudSolarEnergy = function initEfCloudSolarEnergy(element, config, cb)
    {
        const { assetUrl } = config;
        getSolarEnergyForSchool(null).then(response =>
        {
            if (response === null)
            {
                const err = "No response";
                console.error(err);
                cb && cb(err);
            }
            else
            {
                ReactDOM.render(<Embeddable assetUrl={assetUrl}>
                    <SolarEnergy energy={response} />
                </Embeddable>, element);
                cb && cb(null);
            }
        }).catch(err =>
        {
            console.log(err);
            cb && cb(err);
        });
    };
}
else
{
    ReactDOM.render(<BrowserRouter><ErrorBoundary><App /></ErrorBoundary></BrowserRouter>, document.getElementById('ef-dashboard'));
    serviceWorker.unregister();
}
