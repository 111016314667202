import React, { Component } from "react";
import Grid from 'styled-components-grid';

import { getSchoolScore, getSingleFloorInfo } from "../components/data/api";
import TableComponent from "../components/TableComponent";
import Header from "../components/Header";
import manageLanguage from "../components/manageLanguage";
import { ScoreTable, TablePageComponent } from "../components/TablePageComponents";

class SchoolScores extends Component
{
    state = {
        floor: {},
        scores: []
    };

    componentDidMount()
    {
        let id = this.props.getId();
        this.getScores(id);
        this.getTheFloor(id);
    }

    componentWillUnmount()
    {
        this.setState({ floor: {}, scores: [] });
    }

    getTheFloor = (id) =>
    {
        let _this = this;
        getSingleFloorInfo(id).then(response =>
        {
            if (response === null)
            {
                console.error("No response");
            }
            else
            {
                _this.setState({ floor: response });
            }
        }).catch(err =>
        {
            console.log(err);
        });
    }

    getScores = (id) =>
    {
        let _this = this;
        getSchoolScore(id).then(response =>
        {
            if (response === null)
            {
                console.error("No response");
            }
            else
            {
                _this.setState({ scores: response });
            }
        }).catch(er =>
        {
            console.log(er);
        });
    }

    render()
    {
        let floorName = '';
        if (this.state.floor.floor_name)
        {
            floorName = this.state.floor && this.state.floor.floor_name.toUpperCase();
        }
        const { t } = this.props;
        return (
            <>
                <Header
                    firstHeader={floorName}
                    floorId={this.props.getId()}
                    classNameSchoolScores="selected"
                    lang={this.props.getLanguage()}
                    t={this.props.t}
                    pauseInterval={this.props.pauseInterval}
                    startInterval={this.props.startInterval}
                    isPaused={this.props.isPaused}
                    setIsPaused={this.props.setIsPaused}
                />
                <TablePageComponent className="tableAll schoolScores">
                    {this.state.scores.length > 0 &&
                        this.state.scores.map((game, index) =>
                        {
                            return (
                                <Grid.Unit size={{ mobile: 1, tablet: 1 / 2, desktop: 1 / 3 }} key={index}>
                                    <ScoreTable>
                                        <TableComponent
                                            number={index + 1}
                                            gameName={game.gamename.toUpperCase()}
                                            firstScore={game.daily}
                                            secondScore={game.weekly}
                                            selfScore={game.alltime}
                                            t={t}
                                            height="100%"
                                            aspectRatio="60%"
                                        />
                                    </ScoreTable>
                                </Grid.Unit>
                            );
                        })}
                </TablePageComponent>
            </>
        );
    }
}

export default manageLanguage(SchoolScores);
