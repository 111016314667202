function calculateRem(size) {
    return `${size / 16}rem`
}

/**
 *
 * @param {*} size in px
 */
function fontSize(size) {
    return `font-size: ${size}px;
            font-size: ${calculateRem(size)};`
}

export {
    fontSize
}