
import config from "./apiConfig";

function getSchoolScore(id)
{
    return fetch(`${config.url}played-games/scores-for-floor?guid=${id}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);
}

function getOneFloor(id)
{
    return fetch(`${config.url}floors/${id}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);

}

async function getWeeklyHighestSchoolScoresGlobal()
{
    try
    {
        const response = await fetch(`${config.url}played-games/top-week-scores-global`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    }
    catch (error)
    {
        return error;
    }
}

function getWeeklyHighestSchoolScores(id)
{
    console.log("test!");
    console.log("config" + config.url);
    console.log("id" + config.id);
    let url = `${config.url}played-games/top-week-scores?guid=${id}`;
    console.log("Fetching " + url)
    return fetch(`${config.url}played-games/top-week-scores?guid=${id}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);
}

async function getLastWeekHighestScoreGlobal()
{
    try
    {
        const response = await fetch(`${config.url}played-games/top-lastweek-scores-global`, {
            method: 'GET'
        });
        const data = await response.json();
        return data;
    }
    catch (error)
    {
        return error;
    }
}

function getLastWeekHighestScore(id)
{
    return fetch(`${config.url}played-games/top-lastweek-scores?guid=${id}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);
}

function getSolarEnergyForSchool(id)
{
    return fetch(`${config.url}solar-productions/solar-production-daily-public?guid=${id}`, {
        method: 'GET'
    }).then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);
}

function getSingleFloorInfo(id)
{
    return fetch(`${config.url}floors/floor-public?guid=${id}`, {
        method: 'GET'
    })
        .then(response => response.json())
        .then(data =>
        {
            return data;
        })
        .catch(error => error);
}


export
{
    getSchoolScore,
    getWeeklyHighestSchoolScoresGlobal,
    getWeeklyHighestSchoolScores,
    getOneFloor,
    getSingleFloorInfo,
    getLastWeekHighestScoreGlobal,
    getLastWeekHighestScore,
    getSolarEnergyForSchool
};