import React, { Component } from 'react';

import * as Paths from './Paths';

const updateIntervalMs = 15000;

export default (ChildComponent) => {
    class ComposedComponent extends Component {
        componentDidMount() {
            if (!this.props.isPaused) {
                this.startInterval();
                this.updateLanguage(this.props.i18n)
            }
        }

        componentDidUpdate(prevProps, prevState) {
            if (prevProps.match.params.lang !== this.props.match.params.lang) {
                this.updateLanguage(this.props.i18n)
                this.forceUpdate();
            }
            if (prevProps.lng !== this.props.lng) {
                this.changeLanguage(this.props.lng);
                this.forceUpdate();
            }

            if (!this.props.isPaused && (prevProps.isPaused !== this.props.isPaused)) {
                this.pauseInterval();
                this.startInterval();
            } else if (this.props.isPaused && (prevProps.isPaused !== this.props.isPaused)) {
                this.pauseInterval();
            }
        }

        changeLanguage = lng => {
            let path = this.getPath()
            if (path === "/energyFloors/highestScored/:lang/:floorId") {
                return this.props.history.push(Paths.weeklyHighestScoredAllSchool.toPath({ floorId: this.getId(), lang: lng }));
            } else if (path === "/energyFloors/:lang/:floorId") {
                return this.props.history.push(Paths.weeklyScoredSchool.toPath({ floorId: this.getId(), lang: lng }));
            } else if (path === "/energyFloors/solarEnergy/:lang/:floorId") {
                return this.props.history.push(Paths.solarEnergyScoredSchool.toPath({ floorId: this.getId(), lang: lng }));
            }

        };

        updateLanguage = (i18n) => {

            i18n.changeLanguage(this.getLanguage());
        }

        getId = () => {
            return this.props.match.params.floorId;
        }

        pauseInterval = () => {
            clearInterval(this.interval);
        }

        startInterval = () => {
            let id = this.getId();
            let lang = this.getLanguage();
            let path = this.getPath();
            clearInterval(this.interval);
            if (path === "/energyFloors/:lang/:floorId") {
                this.interval = setInterval(() => this.props.history.push(Paths.weeklyHighestScoredAllSchool.toPath({ floorId: id, lang: lang })), updateIntervalMs);
            } else if ((path === "/energyFloors/highestScored/:lang/:floorId")) {
                this.interval = setInterval(() => this.props.history.push(Paths.solarEnergyScoredSchool.toPath({ floorId: id, lang: lang })), updateIntervalMs);
            } else {
                this.interval = setInterval(() => this.props.history.push(Paths.weeklyScoredSchool.toPath({ floorId: id, lang: lang })), updateIntervalMs);
            }
        }

        getLanguage = () => {
            return this.props.match.params.lang;
        }
        getPath = () => {
            return this.props.match.path;
        }

        componentWillUnmount() {
            clearInterval(this.interval);
        }

        render() {
            return (
                <ChildComponent
                    {...this.props}
                    startInterval={this.startInterval}
                    pauseInterval={this.pauseInterval}
                    getId={this.getId}
                    getLanguage={this.getLanguage}
                />
            )
        }
    }

    return (ComposedComponent);
};