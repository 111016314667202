import styled from 'styled-components';
import { grid } from 'styled-components-grid';

const TablePageComponent = styled.div`
    position:static;
    margin-left: 25px;
    margin-right: 25px;
    ${grid({})}
`;


const TablePageContent = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`;

const ScoreTable = styled.div`
    margin-top: 50px;
    position: relative;
`;

export
{
    TablePageComponent,
    TablePageContent,
    ScoreTable
}