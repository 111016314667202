import React, { Component } from "react";

import Grid from 'styled-components-grid';
import { Route, Switch, withRouter } from "react-router-dom";
import { routes } from "./components/data/routes";
import { withTranslation } from 'react-i18next';
import { Reboot } from 'styled-reboot';
import textfit from 'textfit';
import styled from "styled-components";

import * as Paths from './components/Paths';
import TimeBar from "./components/TimeBar";
import Home from "./pages/Home";
import SchoolScores from "./pages/SchoolScores";
import WeeklyMaximumScores from "./pages/WeeklyMaximumScores";
import NoMatch from "./pages/NoMatch";
import SchoolSolarEnergy from "./pages/SchoolSolarEnergy";
import EmbeddedWeeklyMaximumScores from "./embedded/EmbeddedWeeklyMaximumScores";

const AppDiv = styled.div`{
    #root,
    .App,
    .content,
    .contentApp,
    .container-fluid {
        height: 100%;
    }

    .App {
        text-align: center;
        width: 100%;
    }
    .sideBar{
        background-image: linear-gradient(to bottom, #495189, #3c7c99, #87c5d8);
        width: 100%;
        padding-right: 0%;
    }

    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 27px;
    font-weight: normal;
}

    //large width, low height
@media  (max-height: 648px)  and (min-width: 1400px)  {
    .contentApp {
        width: 65%;
    }
    .allScores
    {
        width: 100%;
    }
        .lineScore span {
            position: relative;
            top: -20%;
        }
        .headerContent {
            position: relative;
            top: -6%;
        }
    .rightHeader {
        .sliderHeader {
            div {
            @include font-size(14px);
            }
        }
    }

    //large width, tiny height
    @media  (max-height: 580px) and (min-width: 1400px)  {
    .contentApp {
        width: 50%;
    }

    @media  (min-width: 1400px)  and (max-width: 1580px)
    {
        .lineScore span {
            position: relative;
            top: -50%;
        }
        .headerContent {
            position: relative;
            top: -6%;
        }
    }
    @media  (min-width: 1580px)
    {
        .lineScore span {
            position: relative;
            top: -30%;
        }
        .headerContent {
            position: relative;
            top: -6%;
        }
    }
    .rightHeader {
        .sliderHeader {
            div {
            @include font-size(14px);
            }
        }
        }
    }
}

//very wide and medium height
@media (min-width: 1700px) and (max-height: 800px) and  (min-height: 648px){
    .allScores {
        width: 50%;
    }
    .rightHeader {
        .sliderHeader {
            div {
            @include font-size(14px);
            }

    }
}


//very wide and tiny height
@media (min-width: 1700px) and (max-height: 648px) {
    .rightHeader {
        .sliderHeader {
            div {
            @include font-size(14px);
            }
        }
    }
}
}`;

class App extends Component
{
    constructor(props)
    {
        super();
        this.state = {
            count: 0,
            index: 0,
            isPaused: false
        };
    }

    setIsPaused = (isPaused) =>
    {
        this.setState({ isPaused });
    }

    componentDidMount()
    {
        const index = routes.indexOf(this.props.match.params.path);
        this.setState({ count: index, index });
        window.addEventListener("resize", () => this.onResize());
        this.onResize();
    }

    onResize()
    {
        textfit(document.getElementsByClassName('fitted'));
    }

    renderRoutes = () =>
    {
        return (
            <div className="content">
                <Switch>
                    <Route path={Paths.root.path} exact
                        render={props => <Home
                            match={props.match}
                            location={props.location}
                            history={props.history}
                            t={this.props.t}
                            i18n={this.props.i18n}
                            lng={this.props.lng}
                        />}
                    />
                    <Route path={Paths.weeklyScoredSchoolGlobal.path} exact
                        render={props => <SchoolScores
                            match={props.match}
                            location={props.location}
                            history={props.history}
                            t={this.props.t}
                            i18n={this.props.i18n}
                            lng={this.props.lng}
                            isPaused={this.state.isPaused}
                            setIsPaused={this.setIsPaused}
                        />}
                    />
                    <Route path={Paths.weeklyScoredSchool.path} exact
                        render={props => <SchoolScores
                            match={props.match}
                            location={props.location}
                            history={props.history}
                            t={this.props.t}
                            i18n={this.props.i18n}
                            lng={this.props.lng}
                            isPaused={this.state.isPaused}
                            setIsPaused={this.setIsPaused}
                        />}
                    />
                    <Route path={Paths.weeklyHighestScoredAllSchool.path}
                        render={props => <WeeklyMaximumScores
                            match={props.match}
                            location={props.location}
                            history={props.history}
                            t={this.props.t}
                            i18n={this.props.i18n}
                            lng={this.props.lng}
                            isPaused={this.state.isPaused}
                            setIsPaused={this.setIsPaused}
                        />}
                    />

                    <Route path={Paths.solarEnergyScoredSchool.path}
                        render={props => <SchoolSolarEnergy
                            match={props.match}
                            location={props.location}
                            history={props.history}
                            t={this.props.t}
                            i18n={this.props.i18n}
                            lng={this.props.lng}
                            isPaused={this.state.isPaused}
                            setIsPaused={this.setIsPaused}
                        />}
                    />
                    <Route render={props => <NoMatch
                        match={props.match}
                        location={props.location}
                        history={props.history}
                        t={this.props.t}
                        i18n={this.props.i18n}
                        lng={this.props.lng}
                    />}
                    />
                </Switch>
            </div>
        );
    };

    renderLenght = () =>
    {
        return routes.length;
    };

    updateLanguage = (lng) =>
    {
        this.props.i18n.changeLanguage(lng);
    }

    render()
    {
        return <>
            <Reboot />
            <AppDiv className="App">
                <Switch>
                    <Route path={Paths.embeddedHighScore.path}
                        component={EmbeddedWeeklyMaximumScores}
                    />
                    <Route render={() =>
                        <Grid className="contentApp">
                            <Grid.Unit className="sideBar" size={1 / 6}>
                                <TimeBar t={this.props.t} />
                            </Grid.Unit>
                            <Grid.Unit size={5 / 6}>
                                {this.renderRoutes()}
                            </Grid.Unit>
                        </Grid>} />
                </Switch>
            </AppDiv>
        </>;
    }
}

export default withTranslation()(withRouter(App));
