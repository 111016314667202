import React, { Component } from 'react';

import { getWeeklyHighestSchoolScores, getLastWeekHighestScore } from "../components/data/api";

import WeeklyMaximumScoresContent from '../components/WeeklyMaximumScoresContent';
import Header from '../components/Header';
import manageLanguage from '../components/manageLanguage';

class WeeklyMaximumScores extends Component {
    state = {
        scores: [],
        lastWeekScore: []
    };

    componentDidMount() {
        let id = this.props.getId();
        this.getScores(id);
    }


    componentWillUnmount() {
        clearInterval(this.interval);
        this.setState({ scores: [], lastWeekScore: [] })
    }

    getScores = (id) => {
        let _this = this;
        getWeeklyHighestSchoolScores(id).then(response => {
            if (response === null) {
                console.error("No response");
            } else {
                _this.setState({ scores: response });
            }
        }).catch(error => error);


        getLastWeekHighestScore(id).then(response => {
            if (response === null) {
                console.log("No response");
            } else {
                _this.setState({ lastWeekScore: response })
            }
        }).catch(error => error);
    }

    render()
    {
        return <>
            <Header
                firstHeader={this.props.t("WEEK_KLASSEMENT")}
                floorId={this.props.getId()}
                classNameMaxScore="selected"
                lang={this.props.getLanguage()}
                t={this.props.t}
                pauseInterval={this.props.pauseInterval}
                startInterval={this.props.startInterval}
                isPaused={this.props.isPaused}
                setIsPaused={this.props.setIsPaused}
            />
            <WeeklyMaximumScoresContent scores={this.state.scores} lastWeekScore={this.state.lastWeekScore} t={this.props.t} />
        </>;
    }
}

export default manageLanguage(WeeklyMaximumScores);