import React, { Component } from 'react';
import styled from 'styled-components';

const SpeedometerDiv = styled.div`
{
    height:100%;
    width: 100%;
    display: inline-block;
    svg{
      background-image: url("/slider.png"); //$sideBarTimeColor;
      background-origin: content-box;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

  }`

const totalDegree = 240;

export default class Speedometer extends Component {
    state = {
        maxWatt: 300
    }

    calculateDegree = () => {
        if (this.props.energy) {
            let { powerNow } = this.props.energy;
            powerNow = Number(powerNow);
            const fraction = powerNow / this.state.maxWatt;
            let value = fraction * totalDegree;
            value = Math.min(value, totalDegree);
            value = Math.max(value, 0);
            return value;
        }

    }

    componentDidMount() {
        this.startSvgFile()
    }

    startSvgFile = () => {
        if (this.props.energy) {
            function describeArc(x, y, radius, spread, startAngle, endAngle) {
                var innerStart = polarToCartesian(x, y, radius, endAngle);
                var innerEnd = polarToCartesian(x, y, radius, startAngle);
                var outerStart = polarToCartesian(x, y, radius + spread, endAngle);
                var outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

                var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
                var d = [
                    "M", outerStart.x, outerStart.y,
                    "A", radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
                    "L", innerEnd.x, innerEnd.y,
                    "A", radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
                    "L", outerStart.x, outerStart.y, "Z"
                ].join(" ");

                return d;
            }

            function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
                var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

                return {
                    x: centerX + (radius * Math.cos(angleInRadians)),
                    y: centerY + (radius * Math.sin(angleInRadians))
                };
            }

            var blackpath = describeArc(300, 300, 200, 40, -120, (-120 + this.calculateDegree()))
            document.getElementById("blackpath").setAttribute('d', blackpath)
            var whitePath = describeArc(300, 300, 200, 40, (-120 + this.calculateDegree()), 120)
            document.getElementById("whitePath").setAttribute('d', whitePath)
        }
    }

    createRotate(degree, cx, cy) {
        return "rotate(" + degree + " " + cx + " " + cy + "), translate(" + (cx - 150) + "," + (cy - 31) + ")";
    }

    render() {
        if (this.props.energy) {
            const degreeModified = (this.calculateDegree() - 30);
            const cx = 300;
            const cy = 300;
            const { maxWatt } = this.state;
            const nowValue = this.props.energy.powerNow
            let { t } = this.props
            return (
                <SpeedometerDiv className="speedometer">
                    <svg width="90%" viewBox="0 0 600 600" xmlnsXlink="http://www.w3.org/1999/xlink">
                        { /*<circle cx={cx} cy= {cy} r="3" style={{fill: "black"}}/>*/}
                        <mask id="myMask">
                            <rect x="0" y="0" width="600" height="600" fill="white" />
                            <path id="blackpath" fill="black"></path>
                        </mask>
                        <rect x="0" y="0" width="600" height="600" fill="#d8dadb" mask="url(#myMask)" />
                        <text x="70" y="445" fontSize="30" fontWeight="bold" textAnchor="end" fill="black" >0</text>
                        <text x="70" y="185" fontSize="30" fontWeight="bold" textAnchor="end" fill="black" >{0.25 * maxWatt}</text>
                        <text x="300" y="40" fontSize="30" fontWeight="bold" textAnchor="middle" fill="black" >{0.5 * maxWatt}</text>
                        <text x="530" y="185" fontSize="30" fontWeight="bold" textAnchor="start" fill="black" >{0.75 * maxWatt}</text>
                        <text x="530" y="445" fontSize="30" fontWeight="bold" textAnchor="start" fill="black" >{maxWatt}</text>
                        <path id="whitePath" fill="White"></path>
                        <path
                            style={{ fill: "#4dc0dd" }}
                            d="M 0.17857426,31.112205 132.14286,2.1836346 c 9.48827,-2.08231034 13.7802,-2.01540034 17.67858,-1.78572034 35.47452,6.77506034 33.4613,55.14507074 0,60.17857074 -3.25668,0.27717 -8.65961,0.48589 -17.82541,-1.40924 z"
                            id="needle"
                            transform={this.createRotate(degreeModified, cx, cy)}
                        />
                        <text x="300" y="420" fontSize="30" fontWeight="bold" textAnchor="middle" fill="white" >{t('now')}</text>
                        <text x="300" y="510" fontSize="100" fontWeight="bold" textAnchor="middle" fill="#2963a8" >{nowValue}</text>
                        <text x="300" y="550" fontSize="30" fontWeight="bold" textAnchor="middle" fill="white" >WATT</text>
                    </svg>
                </SpeedometerDiv>
            )
        }
        else {
            return <></>
        }

    }
}
